import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`
const login = async (data) => post(`${url}/login`, data)
const guest = async (data) => get(`${url}/guest`, data)
const sendOpt = async (data) => post(`${url}/send-otp`, data)
const dsecret = async () => get(`${url}/dsecret`)
const verifyEmailForForgotPassword = async (data) => post(`${url}/verify-email`, data)
const resetPassword = async (data) => post(`${url}/reset-password`, data)
const csrf = async (data) => get(`${process.env.VUE_APP_API_DOMAIN}/sanctum/csrf-cookie`, data)
const register = async (data) => post(`${url}/register`, data)
const firebaseRegister = async (data) => post(`${url}/register/firebase`, data)
const one = async (id) => post(`${url}/users/${id}`)
const follow = async (id) => post(`${url}/users/${id}/follow`)
const block = async (id) => post(`${url}/users/${id}/block`)
const profileImage = async (data) => post(`${url}/profile-image`, data)
const profile = async () => get(`${url}/profile`)
const me = async () => get(`${url}/me`)
const fetchSaved = async () => get(`${url}/users/save`)
const fetchFollowings = async (id) => get(`${url}/users/${id}/followings`)
const scroll = async (link) => get(`${link}`)
const saveFCMToken = async (data) => post(`${url}/saveFCMToken`, data)
const fetchAllUsers = async (payload) => get(`${url}/users/fetch`, payload)
const setOnline = async () => get(`${url}/users/online`)
const editRequest = async (payload) => put(`${url}/users/${payload.id}`, payload)
const editConfirmation = async (payload) => post(`${url}/users/${payload.id}/editConfirmation`, payload)
const deleteUser = async (payload) => destroy(`${url}/users/${payload.id}/delete`, payload)
const memberList = async () => get(`${url}/members`)
const removeSaved = async (id) => destroy(`${url}/saved/${id}`)
const fetchUserList = async (users) => get(`${url}/users/fetchUserList`, users)
const warningSeen = async (payload) => post(`${url}/warning-seen`, payload)
const emailVerificationNeeded = async (id) => post(`${url}/users/${id}/email-verification`)
const verifyEmailWithOTP = async (id, data) => post(`${url}/users/${id}/verify-email`, data)
const checkUserHasSingleCompanyRole = async () => get(`${url}/users/check-user-has-single-company-role`)
const visited = async (data) => post(`${url}/users/visited`, data)
const getUserCompanies = async () => get(`${url}/admin/get-user-companies`)

export default {
  checkUserHasSingleCompanyRole,
  dsecret,
  login,
  guest,
  csrf,
  register,
  one,
  firebaseRegister,
  follow,
  block,
  profileImage,
  profile,
  me,
  fetchSaved,
  scroll,
  saveFCMToken,
  fetchAllUsers,
  setOnline,
  editRequest,
  editConfirmation,
  deleteUser,
  memberList,
  removeSaved,
  fetchFollowings,
  fetchUserList,
  warningSeen,
  emailVerificationNeeded,
  verifyEmailWithOTP,
  sendOpt,
  verifyEmailForForgotPassword,
  resetPassword,
  visited,
  getUserCompanies
}
