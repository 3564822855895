import usersRepository from './real/users'
import monitoringRepository from './real/monitoring'
import companiesRepository from './real/companies'
import postsRepository from './real/posts'
import customerCareRepository from './real/customerCare'
import incdientsRepository from './real/incidents'
import salesRepository from './real/sales'
import rolesRepository from './real/roles'
import pollCampaignRepository from './real/pollCampaign'
import developmentsRepository from './real/developments'

const repositories = {
  users: usersRepository,
  companies: companiesRepository,
  monitoring: monitoringRepository,
  posts: postsRepository,
  customerCare: customerCareRepository,
  incidents: incdientsRepository,
  roles: rolesRepository,
  sales: salesRepository,
  pollCampaign: pollCampaignRepository,
  developments: developmentsRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}

export default RepositoryFactory
