import auth from "@/js/helpers/auth"
import { createRouter, createWebHistory } from "vue-router"
import { useUsersStore } from "@/stores/users"

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (!to && !from && !savedPosition) return { x: 0, y: 0 }
  },
  routes: [
    // Home
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home"),
      redirect: { name: "monitoring" },
      meta: {
        requiresAuth: true,
      },
    },
    // Auth
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login"),
      meta: {
        layout: "SignInLayout",
        requiresAuth: false,
      },
    },
    // Monitoring
    {
      path: "/monitoring",
      name: "monitoring",
      component: () => import("@/views/Monitoring.vue"),
      redirect: { name: "overview" },
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "overview",
          name: "overview",
          component: () => import("@/views/MonitoringOverview.vue"),
        },
        {
          path: "members",
          name: "members",
          component: () => import("@/views/MonitoringMembers.vue"),
        },
        // {
        //   path: 'conversion',
        //   name: 'conversion',
        //   component: () => import('@/views/MonitoringConversion.vue')
        // }
      ],
    },
    // Development
    {
      path: "/development",
      name: "development",
      component: () => import("@/views/Development"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/development/:id",
      name: "fileDetails",
      component: () => import("@/views/DevelopmentFileDetails"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/development/file-create",
      name: "fileCreate",
      component: () => import("@/views/DevelopmentFileCreate"),
      meta: {
        requiresAuth: true,
      },
    },
    // Customer Care
    {
      path: "/customer-care",
      name: "customerCare",
      component: () => import("@/views/CustomerCare"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/customer-care/:id",
      name: "customerCareCustomer",
      component: () => import("@/views/CustomerCareCustomer"),
      meta: {
        requiresAuth: true,
      },
    },
    // Incidents
    {
      path: "/incidents",
      name: "incidents",
      component: () => import("@/views/Incidents"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/posts/:id',
      name: 'posts',
      component: () => import('@/views/Post')
    },
    {
      path: "/incident/:id",
      name: "incident",
      component: () => import("@/views/Incident"),
      meta: {
        requiresAuth: true,
      },
    },
    // Companies
    {
      path: "/companies",
      name: "companies",
      component: () => import("@/views/Companies"),
      redirect: { name: "userAndRoles" },
      meta: {
        requiresAuth: true,
      },
      children: [
        // {
        //   path: 'monitoring',
        //   name: 'monitoring',
        //   component: () => import('@/views/CompaniesMonitoring')
        // },
        {
          path: "userAndRoles",
          name: "userAndRoles",
          component: () => import("@/views/CompaniesUserAndRoles"),
        },
        // {
        //   path: 'polls',
        //   name: 'polls',
        //   component: () => import('@/views/CompaniesPolls')
        // },
        {
          path: "invoicing",
          name: "invoicing",
          component: () => import("@/views/CompaniesInvoicing"),
        },
        {
          path: "documents",
          name: "documents",
          component: () => import("@/views/CompaniesDocuments"),
        },
      ],
    },
    // Poll stats
    {
      path: '/sales-officer',
      name: 'sales-officer',
      component: () => import("@/views/SalesOfficer"),
    },
    {
      path: "/poll-campaign",
      name: "pollCampaign",
      component: () => import("@/views/PollCampaign"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/poll-campaign/create",
      name: "createPollCampaign",
      component: () => import("@/views/CreatePollCampaign"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/poll-campaign/info/:id",
      name: "updatePollCampaign",
      component: () => import("@/views/UpdatePollCampaign"),
      meta: {
        requiresAuth: true,
      },
    },
    // Roles
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/Roles"),
      meta: {
        requiresAuth: true,
      },
    },
    // Sales
    {
      path: "/sales-manager",
      name: "sales",
      component: () => import("@/views/Sales"),
      meta: {
        requiresAuth: true,
      },
    },
    // Marketplace
    {
      path: "/marketplace",
      name: "marketPlace",
      beforeEnter() {
        window.location.href = `${process.env.VUE_EUCAPS_URL}/my-eucaps/marketplace`
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import("@/views/NotFound"),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const usersStore = useUsersStore()
  const isLoggedIn = auth.isAuthenticated()
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (isLoggedIn) usersStore.fetch()

  if (requiresAuth && !isLoggedIn) {
    next({ name: "login" })
  } else if (to.name === "login" && isLoggedIn) {
    next({ name: "home" })
  } else {
    next()
  }
})

export default router
