import { defineStore } from "pinia";
import RepositoryFactory from "@/api/repositoryFactory";
import auth from "@/js/helpers/auth";
import { useSnackbarStore } from "./snackbar";
import router from "@/js/router";

const repository = RepositoryFactory.get("users");

export const useUsersStore = defineStore("users", {
  state: () => ({
    users: [],
    roles: [],
    user: null,
    accessToken: null,
    isLoggedIn: false,
    loadingLogin: false,
    locked_at: null,
    locked_to: null,
    loginError: "",
    userComapnies: [],
    selectedCompanyId: null,
  }),
  getters: {
    isAdmin: (state) => {
      return state.roles.some(role => role.name === 'admin-access');
    }
  },
  actions: {
    async checkUserHasSingleCompanyRole() {
      this.loading = true
      try {
        const response = await repository.checkUserHasSingleCompanyRole()
        this.loading = false
        if (response.status === 200 && response.data) {
          this.hasSingleCompanyRole = true
          return response.data
        } else {
          this.hasSingleCompanyRole = false
          return false
        }
      } catch (e) {
        const snackbar = useSnackbarStore()
        this.loading = false
        snackbar.snack({ type: 'error', e: e, show: true, autoclose: true })
      }
    },
    async fetch () {
      if (auth.getAccessToken()) {
        this.setToken(auth.getAccessToken());
      }
      if (localStorage.user !== undefined && localStorage.user !== "") {
        this.setUser(JSON.parse(localStorage.user));
        this.setRoles(JSON.parse(localStorage.roles));
      }
      if (this.accessToken) {
        try {
          const response = await repository.me();
          if (response.data.username !== "Guest") {
            this.setUser(response.data);
            this.setRoles(response.data.roles);
          }
        } catch (e) {
          const snackbar = useSnackbarStore();
          snackbar.snack({ type: "error", e: e, show: true, autoclose: true });
        }
      }
      if (this.isAdmin) {
        try {
          const response = await repository.getUserCompanies();
          if (response.status === 200 && response.data.length > 0) {
            this.userComapnies = response.data;
            this.selectedCompanyId = response.data[0]?.id;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    visited(toPath) {
      repository.visited({ path: toPath });
    },
    async login(payload) {
      try {
        this.loadingLogin = true;

        const response = await repository.login(payload);
        this.loadingLogin = false;
        if (response.status === 200) {
          if (response.data.token && response.data.token.length > 0) {
            // console.log('login data', response.data.user)
            this.isLoggedIn = true;
            // dispatch('setFirebaseToken', localStorage.getItem('fcmToken'))
            this.setToken(response.data.token);
            this.setUser(response.data.user);
            this.locked_at = response.data.user.profile.locked_at;
            this.locked_to = response.data.user.profile.locked_to;
            this.setRoles(response.data.user.roles);

            router.push({
              name: "home",
            });
          }
        }
      } catch (e) {
        const snackbar = useSnackbarStore();

        snackbar.snack({ type: "error", e: e, show: true, autoclose: true });

        this.loadingLogin = false;
        // commit('SET_ALERT', { create: true,  title: 'Login Error!', body: 'Invalid credentials. please check your Email Id or Password' })
        this.loginError = e.response.data.message;
      }
    },
    async logout() {
      try {
        localStorage.isLoggedIn = false;
        localStorage.removeItem("user");
        localStorage.removeItem("roles");
        localStorage.removeItem("email");
        this.setToken(null);
        this.isLoggedIn = false;
        auth.clearToken();
        router.push({ name: "login" });
      } catch (e) {
        const snackbar = useSnackbarStore();
        snackbar.snack({ type: "error", e: e, show: true, autoclose: true });
      }
    },
    setToken(token) {
      this.accessToken = token;
      auth.storeToken(token);
    },
    setUser(user) {
      this.user = { ...user };
      localStorage.user = JSON.stringify(this.user);
    },
    setRoles(roles) {
      this.roles = [...roles];
      localStorage.roles = JSON.stringify(this.roles);
    },
    setTestAction(newValue) {
      this.test = newValue;
    },
  },
});
